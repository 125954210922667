import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import Registration from './component/registration';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faClose, faPoll} from '@fortawesome/free-solid-svg-icons';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';

function App() {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const copyText=(value)=>{
    navigator.clipboard.writeText(value);
    handleClick();
  }

  return (
    <div className="App">
      <Snackbar
        open={open}
        autoHideDuration={6000000}
        onClose={handleClose}
        anchorOrigin={{vertical:"top", horizontal:"center"}}
        sx={{marginTop: "100px"}}
      ><Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >Text Copied!</Alert>
      </Snackbar>
        <div id="home" className="header">
          <div className="w-layout-blockcontainer main-container w-container">
            <div data-animation="default" data-collapse="medium" data-duration={400} data-easing="ease" data-easing2="ease" role="banner" className="header-inner w-nav">
              <a className="logo w-inline-block">
                <img loading="lazy" src="../../HomeCleaningHtml_files/spartan.png" alt="" className="logo-img" />
              </a>
              <p className="hero-heading-text">SPARTAN HOME CLEANING MICHIGAN</p>
              <div className="header-right-side">
                <Button id="copy-number-text" variant="contained" style={{fontWeight: "500", fontSize: 14,  background: "seagreen", marginRight: 10}} onClick={copyText.bind(this, "help.homeglowcleaning@gmail.com") }><FontAwesomeIcon icon={faEnvelope} style={{paddingRight: 10}} /> help.homeglowcleaning@gmail.com </Button>
                <Button id="copy-number-text" variant="contained" style={{display: "none", fontWeight: "500", fontSize: 14,  background: "seagreen"}} onClick={copyText.bind(this, "(646) 269-9212)") }><FontAwesomeIcon icon={faPhone} style={{paddingRight: 10}} /> (646) 269-9212 </Button>
                <a href='https://forms.gle/AUyXiG9SvXxNdaLJ9' target="_blank">
                  <Button id="copy-number-text" variant="contained" style={{fontWeight: "500", fontSize: 14,  background: "darkgoldenrod"}} ><FontAwesomeIcon icon={faPoll} style={{paddingRight: 10}} />Free Survey !</Button>
                </a>
              </div>
              <div className="header-right-side-mobile">
                <Button id="copy-number-text" variant="contained" style={{height: 40, width: 100, fontWeight: "500", fontSize: 14,  background: "seagreen", marginRight: 10}}  onClick={copyText.bind(this, "help.homeglowcleaning@gmail.com") }><FontAwesomeIcon icon={faEnvelope} style={{}} /></Button>
                <Button id="copy-number-text" variant="contained" style={{ display: "none", height: 40, width: 100, fontWeight: "500", fontSize: 14,  background: "seagreen"}} onClick={copyText.bind(this, "(646) 269-9212)") }><FontAwesomeIcon icon={faPhone} style={{height: "revert-layer"}} /></Button>
                <a href='https://forms.gle/AUyXiG9SvXxNdaLJ9' target="_blank">
                  <Button id="copy-number-text" variant="contained" style={{ height: 40, width: 100, fontWeight: "500", fontSize: 14,  background: "darkgoldenrod"}} ><FontAwesomeIcon icon={faPoll} style={{height: "revert-layer"}} /></Button>
                </a>
              </div>
              <div className="w-nav-overlay" data-wf-ignore id="w-nav-overlay-0" />
            </div>
          </div>
        </div>
        <div className="hero-section">
          <div className="w-layout-blockcontainer main-container w-container">
            <div className="hero-inner">
              <h1 className="hero-heading">
                Strength in Every Clean.
              </h1>
              <p className="hero-location-text">Hastings, Grand Rapids, Kalamazoo, Battlecreek Area</p>
              <div className="hero-image-div">
                <img src="../../HomeCleaningHtml_files/66b619a294c7ee5c700558f7_hero-dec.png" loading="lazy" alt="" className="hero-lines" />
                <img src="../../HomeCleaningHtml_files/shutterstock_1934018414-1-1-800x534-1.jpg" loading="lazy" sizes="(max-width: 479px) 77vw, (max-width: 1439px) 49vw, 690px"  alt="" className="hero-image" />
              </div>
              <p className="hero-paragraph">Catered Home Cleaning Service.<br/>To You and for Your Home Care Needs</p>
              <Registration></Registration>
            </div>
          </div>
        </div>
        <div className="services-section">
          <div className="w-layout-blockcontainer main-container w-container">
            <div className="service-title">
              <div id="id-374f5d3c-d392-a5b8-6341-fb5e6be3c90a"data-w-id="374f5d3c-d392-a5b8-6341-fb5e6be3c90a" className="service-title-item">
                <img src="../../HomeCleaningHtml_files/66b619a28e804a0cfc65d15f_create.png" loading="lazy" alt="" className="service-title-icon" />
                <h2 className="service-title-heading">Book.</h2>
              </div>
              <div id="id-374f5d3c-d392-a5b8-6341-fb5e6be3c90e" data-w-id="374f5d3c-d392-a5b8-6341-fb5e6be3c90e" className="service-title-item">
                <img src="../../HomeCleaningHtml_files/66b619a33d6d08103e42a4fc_express.png" loading="lazy" alt="" className="service-title-icon" />
                <h2 className="service-title-heading">Invoice.</h2>
              </div>
              <div id="id-374f5d3c-d392-a5b8-6341-fb5e6be3c912" data-w-id="374f5d3c-d392-a5b8-6341-fb5e6be3c912" className="service-title-item">
                <img src="../../HomeCleaningHtml_files/66b619a5144b9f1b7a361949_transform.png" loading="lazy" alt="" className="service-title-icon" />
                <h2 className="service-title-heading">Service.</h2>
              </div>
            </div>
            <div className="service-flex">
              <div data-w-id="374f5d3c-d392-a5b8-6341-fb5e6be3c917" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="service-item">
                <div className="service-item-title">
                  <div className="service-number">01</div>
                  <div className="service-cateogry">Satisfaction</div>
                </div>
                <div id="w-node-_374f5d3c-d392-a5b8-6341-fb5e6be3c91d-ffc20523" className="service-content">
                  <img src="../../HomeCleaningHtml_files/1701206280239.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 49vw, (max-width: 991px) 47vw, (max-width: 1439px) 25vw, 345px" alt="" className={"service-img image-tint"} />
                  <div className="service-text">Tailor services to meet the specific need of each client.</div>
                </div>
              </div>
              <div id="w-node-_374f5d3c-d392-a5b8-6341-fb5e6be3c921-ffc20523" data-w-id="374f5d3c-d392-a5b8-6341-fb5e6be3c921" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="service-item">
                <div className="service-item-title">
                  <div className="service-number">02</div>
                  <div className="service-cateogry">Reliability</div>
                </div>
                <div id="w-node-_374f5d3c-d392-a5b8-6341-fb5e6be3c927-ffc20523" className="service-content">
                  <img src="../../HomeCleaningHtml_files/aca0c885-7088-4761-bad6-ed70ef8f9a69-1024x575.png" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 49vw, (max-width: 991px) 47vw, (max-width: 1439px) 25vw, 345px" alt="" className={"service-img image-tint"} />
                  <div className="service-text">Offer consistent and reliable service to build trust.</div>
                </div>
              </div>
              <div id="w-node-_374f5d3c-d392-a5b8-6341-fb5e6be3c92b-ffc20523" data-w-id="374f5d3c-d392-a5b8-6341-fb5e6be3c92b" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="service-item">
                <div className="service-item-title">
                  <div className="service-number">03</div>
                  <div className="service-cateogry">Safety Compliance</div>
                </div>
                <div id="w-node-_374f5d3c-d392-a5b8-6341-fb5e6be3c931-ffc20523" className="service-content">
                  <img src="../../HomeCleaningHtml_files/house-cleaning-1.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 49vw, (max-width: 991px) 47vw, (max-width: 1439px) 25vw, 345px" alt="" className="service-img" />
                  <div className="service-text">Ensuring safety in home care experience.</div>
                </div>
              </div>
              <div id="w-node-_374f5d3c-d392-a5b8-6341-fb5e6be3c935-ffc20523" data-w-id="374f5d3c-d392-a5b8-6341-fb5e6be3c935" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="service-item">
                <div className="service-item-title">
                  <div className="service-number">04</div>
                  <div className="service-cateogry">Environmental Friendly</div>
                </div>
                <div id="w-node-_374f5d3c-d392-a5b8-6341-fb5e6be3c93b-ffc20523" className="service-content">
                  <img src="../../HomeCleaningHtml_files/eco-image.jpg" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 49vw, (max-width: 991px) 47vw, (max-width: 1439px) 25vw, 345px" alt="" className="service-img" />
                  <div className="service-text">Use eco-friendly and non-toxic cleaning products.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-section">
          <div className="w-layout-blockcontainer main-container w-container">
            <div className="main-title">
              <div className="main-title-icon-box">
                <img src="../../HomeCleaningHtml_files/66b619a2602dc0c46d0f21d2_module-title-icon.png" loading="lazy" alt="" className="main-title-icon" />
              </div>
              <h3 className="main-title-heading">projects</h3>
            </div>
            <div className="work-wrapper w-dyn-list">
              <div role="list" className="work-list w-dyn-items">
                <div role="listitem" className="w-dyn-item">
                  <a data-w-id="3db0ba9d-8530-624c-5c9c-d5a484db3fbb" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="work-link w-inline-block">
                    <img src="../../HomeCleaningHtml_files/clean-living-room.jpg" loading="lazy" alt="" style={{width: 690, height: 460}} className="main-image" />
                    <div className="work-overlay">
                      <div className="work-cateogry">Living Room</div>
                      <h2 className="work-heading">Innovative Cleansing fit for Design  Vision</h2>
                    </div>
                  </a>
                </div>
                <div role="listitem" className="w-dyn-item">
                  <a data-w-id="3db0ba9d-8530-624c-5c9c-d5a484db3fbb" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="work-link w-inline-block">
                    <img src="../../HomeCleaningHtml_files/clean-kitchen.jpg" loading="lazy" alt="" style={{width: 690, height: 460}} className="main-image" />
                    <div className="work-overlay" >
                      <div className="work-cateogry">Kitchen</div>
                      <h2 className="work-heading" >Welcoming & Dustless Appearance</h2>
                    </div>
                  </a>
                </div>
                <div role="listitem" className="w-dyn-item">
                  <a data-w-id="3db0ba9d-8530-624c-5c9c-d5a484db3fbb" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="work-link w-inline-block">
                    <img src="../../HomeCleaningHtml_files/clean-bedroom.jpg" loading="lazy" alt="" style={{width: 690, height: 460}} className="main-image" />
                    <div className="work-overlay" >
                      <div className="work-cateogry">Bedroom</div>
                      <h2 className="work-heading" >Modern Crafting & Visual Excellence</h2>
                    </div>
                  </a>
                </div>
                <div role="listitem" className="w-dyn-item">
                  <a data-w-id="3db0ba9d-8530-624c-5c9c-d5a484db3fbb" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="work-link w-inline-block">
                    <img src="../../HomeCleaningHtml_files/clean-bathroom.jpg" loading="lazy" alt="" style={{width: 690, height: 460}} className="main-image" />
                    <div className="work-overlay">
                      <div className="work-cateogry">Bathroom</div>
                      <h2 className="work-heading">Experience Showcase Sanctuary of Cleanliness</h2>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonials-section">
          <div className="w-layout-blockcontainer main-container w-container">
            <div className="main-title">
              <div className="main-title-icon-box">
                <img src="../../HomeCleaningHtml_files/66b619a2602dc0c46d0f21d2_module-title-icon.png" loading="lazy" alt="" className="main-title-icon" />
              </div>
              <h3 className="main-title-heading">testimonials</h3>
            </div>
            <div className="testimonials-grid">
              <div id="w-node-_8742cea8-be09-bb6c-25b8-561696ecf705-ffc20523" data-w-id="8742cea8-be09-bb6c-25b8-561696ecf705" style={{opacity: 1}} className="testimonial-item">
                <div className="test-upper">
                  <img src="../../HomeCleaningHtml_files/66b619a40e72d5f9c6be9ae1_heart-straight-thin.png" loading="lazy" width={28} alt="" className="heart-icon" />
                  <h4 className="test-heading">Ronny James</h4>
                  <div className="test-image-box">
                    <img src="../../HomeCleaningHtml_files/66b619a544b43e40dc96225e_testimonial2.jpg" loading="lazy" width={130} alt="" className="test-image" />
                  </div>
                </div>
                <p className="test-paragraph">Lapaz consistently delivers stunning work with their innovative approach and keen attention to detail.</p>
              </div>
              <div id="w-node-_8742cea8-be09-bb6c-25b8-561696ecf70e-ffc20523" data-w-id="8742cea8-be09-bb6c-25b8-561696ecf70e" style={{opacity: 1}} className="testimonial-item">
                <div className="test-upper">
                  <img src="../../HomeCleaningHtml_files/66b619a40e72d5f9c6be9ae1_heart-straight-thin.png" loading="lazy" width={28} alt="" className="heart-icon" />
                  <h4 className="test-heading">Joe Satriani</h4>
                  <div className="test-image-box">
                    <img src="../../HomeCleaningHtml_files/66b619a59190da89a6a90b57_testimonial3.jpg" loading="lazy" width={130} alt="" className="test-image" />
                  </div>
                </div>
                <p className="test-paragraph">Lapaz consistently delivers stunning work with their innovative approach and keen attention to detail.</p>
              </div>
              <div id="w-node-_8742cea8-be09-bb6c-25b8-561696ecf717-ffc20523" data-w-id="8742cea8-be09-bb6c-25b8-561696ecf717" style={{opacity: 1}} className="testimonial-item">
                <div className="test-upper">
                  <img src="../../HomeCleaningHtml_files/66b619a40e72d5f9c6be9ae1_heart-straight-thin.png" loading="lazy" width={28} alt="" className="heart-icon" />
                  <h4 className="test-heading">Steve Vai</h4>
                  <div className="test-image-box">
                    <img src="../../HomeCleaningHtml_files/66b619a54354dd19551408cf_testimonial5.jpg" loading="lazy" width={130} alt="" className="test-image" />
                  </div>
                </div>
                <p className="test-paragraph">Lapaz consistently delivers stunning work with their innovative approach and keen attention to detail.</p>
              </div>
              <div id="w-node-_8742cea8-be09-bb6c-25b8-561696ecf720-ffc20523" data-w-id="8742cea8-be09-bb6c-25b8-561696ecf720" style={{opacity: 1}} className="testimonial-item">
                <div className="test-upper">
                  <img src="../../HomeCleaningHtml_files/66b619a40e72d5f9c6be9ae1_heart-straight-thin.png" loading="lazy" width={28} alt="" className="heart-icon" />
                  <h4 className="test-heading">Lumber Jack</h4>
                  <div className="test-image-box">
                    <img src="../../HomeCleaningHtml_files/66b619a57e915f0f477b3c26_testimonial.jpg" loading="lazy" width={130} alt="" className="test-image" />
                  </div>
                </div>
                <p className="test-paragraph">Lapaz consistently delivers stunning work with their innovative approach and keen attention to detail.</p>
              </div>
              <div id="w-node-_8742cea8-be09-bb6c-25b8-561696ecf729-ffc20523" data-w-id="8742cea8-be09-bb6c-25b8-561696ecf729" style={{opacity: 1}} className="testimonial-item">
                <div className="test-upper">
                  <img src="../../HomeCleaningHtml_files/66b619a40e72d5f9c6be9ae1_heart-straight-thin.png" loading="lazy" width={28} alt="" className="heart-icon" />
                  <h4 className="test-heading">Nate Wilson</h4>
                  <div className="test-image-box">
                    <img src="../../HomeCleaningHtml_files/66b619a5323d51680778789e_testimonial4.jpg" loading="lazy" width={130} alt="" className="test-image" />
                  </div>
                </div>
                <p className="test-paragraph">Lapaz consistently delivers stunning work with their innovative approach and keen attention to detail.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="clients-section">
          <div className="w-layout-blockcontainer main-container w-container">
            <div className="main-title">
              <div className="main-title-icon-box">
                <img src="../../HomeCleaningHtml_files/66b619a2602dc0c46d0f21d2_module-title-icon.png" loading="lazy" alt="" className="main-title-icon" />
              </div>
              <h3 className="main-title-heading">clients</h3>
            </div>
            <div className="clients-grid">
              <div id="w-node-_39c6e825-882b-f17c-1add-8746dcfd96de-ffc20523" data-w-id="39c6e825-882b-f17c-1add-8746dcfd96de" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="client-item">
                <img src="../../HomeCleaningHtml_files/66b619a748c9b8fa2a7563fb_Boltshift.png" loading="lazy" width="399.5" alt="" className="client-img" />
              </div>
              <div id="w-node-ec7da196-2e33-af43-64fe-0f7f73016b8d-ffc20523" data-w-id="ec7da196-2e33-af43-64fe-0f7f73016b8d" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="client-item">
                <img src="../../HomeCleaningHtml_files/66b619a7b05eb79120aef2e2_CloudWatch.png" loading="lazy" width="399.5" sizes="(max-width: 479px) 100vw, (max-width: 767px) 41vw, (max-width: 991px) 28vw, (max-width: 1439px) 16vw, 227.59375px" alt="" srcSet="https://cdn.prod.website-files.com/66b61432d0349701ffc2051d/66b619a7b05eb79120aef2e2_CloudWatch-p-500.png 500w, https://cdn.prod.website-files.com/66b61432d0349701ffc2051d/66b619a7b05eb79120aef2e2_CloudWatch.png 799w" className="client-img" />
              </div>
              <div id="w-node-_0595ae84-ca6b-2870-9f96-00512a410f44-ffc20523" data-w-id="0595ae84-ca6b-2870-9f96-00512a410f44" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="client-item">
                <img src="../../HomeCleaningHtml_files/66b619a7eb92d797d1e66866_Clandestine.png" loading="lazy" width="399.5" sizes="(max-width: 479px) 100vw, (max-width: 767px) 41vw, (max-width: 991px) 28vw, (max-width: 1439px) 16vw, 227.609375px" alt="" srcSet="https://cdn.prod.website-files.com/66b61432d0349701ffc2051d/66b619a7eb92d797d1e66866_Clandestine-p-500.png 500w, https://cdn.prod.website-files.com/66b61432d0349701ffc2051d/66b619a7eb92d797d1e66866_Clandestine.png 739w" className="client-img" />
              </div>
              <div id="w-node-_6995be8f-71e0-c0be-4c35-4fcf6494d14d-ffc20523" data-w-id="6995be8f-71e0-c0be-4c35-4fcf6494d14d" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="client-item">
                <img src="../../HomeCleaningHtml_files/66b619a72a638f4d0ede45f6_Biosynthesis.png" loading="lazy" width="399.5" sizes="(max-width: 479px) 100vw, (max-width: 767px) 41vw, (max-width: 991px) 28vw, (max-width: 1439px) 16vw, 227.59375px" alt="" srcSet="https://cdn.prod.website-files.com/66b61432d0349701ffc2051d/66b619a72a638f4d0ede45f6_Biosynthesis-p-500.png 500w, https://cdn.prod.website-files.com/66b61432d0349701ffc2051d/66b619a72a638f4d0ede45f6_Biosynthesis.png 777w" className="client-img" />
              </div>
              <div id="w-node-a6802749-cf05-9f91-73ad-f6d9c0241a74-ffc20523" data-w-id="a6802749-cf05-9f91-73ad-f6d9c0241a74" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="client-item">
                <img src="../../HomeCleaningHtml_files/66b619a6e2aee30fcbd8a40d_Alt+Shift.png" loading="lazy" width="399.5" alt="" className="client-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="blog-section">
          <div className="w-layout-blockcontainer main-container w-container">
            <div className="main-title">
              <div className="main-title-icon-box">
                <img src="../../HomeCleaningHtml_files/66b619a2602dc0c46d0f21d2_module-title-icon.png" loading="lazy" alt="" className="main-title-icon" />
              </div>
              <h3 className="main-title-heading">Our Process</h3>
            </div>
            <div className="blog-list-wrapper w-dyn-list">
              <div role="list" className="blog-list w-dyn-items">
                <div role="listitem" className="w-dyn-item">
                  <div data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb09" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="blog-item">
                    <img style={{transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb14" alt="" src="../../HomeCleaningHtml_files/All-About-Dusting-feat.jpg" loading="lazy" className="blog-image" />
                    <div id="w-node-d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb0a-ffc20523" className="blog-left" >
                      <a data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb0b" className="blog-category">Dusting Surface Layer</a>
                      <h6 className="blog-heading">Promoting Comfort Living</h6>
                      <div className="blog-author-and-arrow">
                        <p style={{fontFamily: 'none'}}>Dusting is a critical component of maintaining a clean and healthy living environment. This includes the health benefits of reducing allergens and preventing respiratory problems. Our mission is to maintain a pleasant & aesthetic appeal to a finished and well polished service. Other benefits include preventing damage to electronics, promoting longer life of devices, promoting comfortable living environment, improving air quality and well being while reducing pest problems.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="w-dyn-item">
                  <div data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb09" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="blog-item">
                    <img style={{transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb14" alt="" src="../../HomeCleaningHtml_files/vacuum.png" loading="lazy" className="blog-image" />
                    <div id="w-node-d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb0a-ffc20523" className="blog-left">
                      <a data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb0b" className="blog-category">Power Compact Silent Vacuuming</a>
                      <h2 className="blog-heading">Dirt & Debris Free</h2>
                      <div className="blog-author-and-arrow">
                        <p style={{fontFamily: 'none'}}>
                          We understand the importance of maintaining cleanliness by removing locked in dirt and debris from carpets, rugs and flooring. Our job is to prevent accumalating odors and toxins that build up deep within fabric layers. Our mission is to extend the life of flooring and furniture, preserving carpet quality and protecting fabric wear and tear prematurely.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="w-dyn-item">
                  <div data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb09" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="blog-item">
                    <img style={{transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb14" alt="" src="../../HomeCleaningHtml_files/steam-mop.jpg" loading="lazy" className="blog-image" />
                    <div id="w-node-d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb0a-ffc20523" className="blog-left">
                      <a data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb0b" className="blog-category">Disinfectant Steam Mopping</a>
                      <h2 className="blog-heading">Reduce Germs and Bacteria</h2>
                      <div className="blog-author-and-arrow">
                        <p style={{fontFamily: 'none'}}>
                          Our disinfectant steam mopping is a powerful cleaning method that combines the effectiveness of steam with germ-kill properties. This method, using high temperature steam is not only eco-friendly (reduce chemical use) but also, safe for family and pets. Our mission is to provide deep cleans and sanitize surfaces, enhance air quality by removing odors and reducing airborne particles that builds up from germs, viruses and fungi that builds up in surface flooring. Our methods are safe for various types of flooring.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div role="listitem" className="w-dyn-item">
                  <div data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb09" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="blog-item">
                    <img style={{transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb14" alt="" src="../../HomeCleaningHtml_files/window-cleaning.png" loading="lazy" className="blog-image" />
                    <div id="w-node-d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb0a-ffc20523" className="blog-left">
                      <a data-w-id="d408aa2d-7ef5-ad97-f42a-b8b4bcbcbb0b" className="blog-category">Spotless Window Cleaning</a>
                      <h2 className="blog-heading">Enhance Curb Appeal and Maximize Natural lighting</h2>
                      <div className="blog-author-and-arrow">
                        <p style={{fontFamily: 'none'}}>
                          Our methods for full home experience also includes window cleaning, protecting window integrity, enhances visibility, preventing build up of mildue stain residue that builds ontop of window surfaces. Our methods prevents glass degradation and avoids damage issues such as cracks and leaks to loose fittings early on. These methods help prevent property owners and lease owners from costly repairs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="facts-section">
          <div className="w-layout-blockcontainer main-container w-container">
            <div className="main-title">
              <div className="main-title-icon-box">
                <img src="../../HomeCleaningHtml_files/66b619a2602dc0c46d0f21d2_module-title-icon.png" loading="lazy" alt="" className="main-title-icon" />
              </div>
              <h3 className="main-title-heading">cool facts</h3>
            </div>
            <div id="w-node-_6a4234c9-82bc-d095-328c-b2441c7c1df4-ffc20523" data-w-id="6a4234c9-82bc-d095-328c-b2441c7c1df4" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}} className="w-layout-layout about-grid wf-layout-layout">
              <div id="w-node-_6a4234c9-82bc-d095-328c-b2441c7c1df5-ffc20523" className="w-layout-cell about-item">
                <div className="about-item-wrapper">
                  <div className="about-number">235+</div>
                  <div className="about-item-text">finished projects</div>
                </div>
              </div>
              <div id="w-node-_6a4234c9-82bc-d095-328c-b2441c7c1dfb-ffc20523" className="w-layout-cell about-item">
                <div className="about-item-wrapper">
                  <div className="about-number">25+</div>
                  <div className="about-item-text">service members</div>
                </div>
              </div>
              <div id="w-node-_6a4234c9-82bc-d095-328c-b2441c7c1e01-ffc20523" className="w-layout-cell about-item">
                <div className="about-item-wrapper">
                  <div className="about-number">138+</div>
                  <div className="about-item-text">happy customer</div>
                </div>
              </div>
              <div id="w-node-_6a4234c9-82bc-d095-328c-b2441c7c1e07-ffc20523" className="w-layout-cell about-item">
                <div className="about-item-wrapper">
                  <div className="about-number">42+</div>
                  <div className="about-item-text">loyal partners</div>
                </div>
              </div>
              <div id="w-node-_6a4234c9-82bc-d095-328c-b2441c7c1e0d-ffc20523" className="w-layout-cell about-item">
                <div className="about-item-wrapper">
                  <div className="about-number">15,628+</div>
                  <div className="about-item-text">cofee drinked</div>
                </div>
              </div>
              <div id="w-node-_6a4234c9-82bc-d095-328c-b2441c7c1e13-ffc20523" className="w-layout-cell about-item">
                <div className="about-item-wrapper">
                  <div className="about-number">10k+</div>
                  <div className="about-item-text">lines of programming</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="work-with-us-section">
          <div data-w-id="75fa511d-f1db-8e40-1fe8-7d8c6ae73dbe" className="work-with-us-text" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}}>Interested In Getting Involved?</div>
          <h2 data-w-id="75fa511d-f1db-8e40-1fe8-7d8c6ae73dc0" className="work-with-us-heading" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}}>let's team up</h2>
          <a href="https://forms.gle/5ioeh6MtD7Syd4Bz6" target="_blank" data-w-id="75fa511d-f1db-8e40-1fe8-7d8c6ae73dc2" className="yellow-button w-button" style={{opacity: 1, transform: 'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)', transformStyle: 'preserve-3d'}}>Partner With Us</a>
        </div>
        <div className="footer">
          <div className="inner-footer">
            <div className="w-layout-blockcontainer main-container w-container">
              <div className="footer-infos">
                <div id="w-node-_6880a81a-2437-abd4-a625-0caf295ab713-295ab70f" className="footer-map">
                  <img src="../../HomeCleaningHtml_files/66b619a448c9b8fa2a755891_map-pin-thin.png" loading="lazy" width={40} alt="" className="footer-map-pin" />
                  <div className="footer-text">
                    Hastings, Michigan
                  </div>
                </div>
                <div id="w-node-_6880a81a-2437-abd4-a625-0caf295ab719-295ab70f" className="footer-mail">
                  <img src="../../HomeCleaningHtml_files/66b619a3caa4490f04cff09d_envelope-thin.png" loading="lazy" width={47} alt="" className="footer-icon" />
                  <a href="mailto:hello@lapaz.webflow.io" className="footer-text">help.homeglowcleaning@gmail.com</a>
                  {/* <a href="mailto:hello@lapaz.webflow.io" className="footer-text">+1 646-269-9212</a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="w-layout-blockcontainer main-container w-container">
              <div className="copyright-inner">
                <div className="footer-text">
                  Spartan Home Glow Cleaning &nbsp;- &nbsp;© 2024 &nbsp;Made by<a href="https://www.linkedin.com/in/rafat-khandaker-1ba23730b" target="_blank" className="footer-text copyright-link">Rafat Khandaker</a>
                </div>
                <div className="foot-socials">
                  <a href="http://www.facebook.com/" target="_blank" className="social-link w-inline-block">
                    <img width={40} loading="lazy" alt="" src="../../HomeCleaningHtml_files/66b619a3227936dca5e3fcae_instagram-logo-thin.png" className="social-icon" />
                  </a>
                  <a href="http://www.facebook.com/" target="_blank" className="social-link w-inline-block">
                    <img width={40} loading="lazy" alt="" src="../../HomeCleaningHtml_files/66b619a22a638f4d0ede43bc_facebook-logo-thin.png" className="social-icon" />
                  </a>
                  <a href="http://www.facebook.com/" target="_blank" className="social-link w-inline-block">
                    <img width={40} loading="lazy" alt="" src="../../HomeCleaningHtml_files/66b619a26a99c078275d50a1_dribbble-logo-thin.png" className="social-icon" />
                  </a>
                  <a href="http://www.facebook.com/" target="_blank" className="social-link w-inline-block">
                    <img width={40} loading="lazy" alt="" src="../../HomeCleaningHtml_files/66b619a21d59db54311ae9c0_linkedin-logo-thin.png" className="social-icon" />
                  </a>
                </div>
              </div>
              {/* <a href="" className="footer-text license-footer">License</a> */}
            </div>
            <a href="#home" className="backtop w-inline-block">
              <img src="../../HomeCleaningHtml_files/66b619a6e4db7c9808ab26bd_arrow.png" loading="lazy" width="34.5" alt="" className="backtop-ing" />
            </a>
          </div>
        </div>
      </div>
  );
}

export default App;
